.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  > li {
    flex: 1 0 4rem;
  }
}

.icons-list-wrap {
  overflow: hidden;
}

.icons-list-item {
  text-align: center;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  i {
    font-size: 1.25rem;
  }
}
