.img-gallery {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
  margin-bottom: -0.5rem;

  > .col,
  > [class*="col-"] {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 0.5rem;
  }
}
