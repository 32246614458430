@keyframes status-pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.32;
  }
}

.status-icon {
  content: "";
  width: px2rem(8px);
  height: px2rem(8px);
  display: inline-block;
  background: currentColor;
  border-radius: 50%;
  transform: translateY(-1px);
  margin-right: 0.375rem;
  vertical-align: middle;
}

.status-animated {
  animation: 1s status-pulse infinite ease;
}
