.list-inline-dots {
  .list-inline-item {
    + .list-inline-item {
      &:before {
        content: "· ";
        margin-left: -2px;
        margin-right: 3px;
      }
    }
  }
}

.list-separated {
}

.list-separated-item {
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: 1px solid $border-color;
  }
}
