
@import "src/assets/custom";
.brand-title {
  color: #4a4a4a; // $primary;
  font-weight: normal;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  margin-top: 4px;
  margin-left: 3px;
  //filter: brightness(1.5);
}
.brand-tagline {
  font-weight: 600;
}

.collapser {
  background: white;
}

.my-header {
  width: 100%;
}

.my-navbar-toggle {
  border: none;
}
