.social-links {
  li {
    a {
      background: #f8f8f8;
      border-radius: 50%;
      color: $text-muted;
      display: inline-block;
      height: 2.2rem;
      width: 2.2rem;
      line-height: 2.2rem;
      text-align: center;
    }
  }
}
