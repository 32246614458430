.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  display: inline-block;
  background: $gray-400 no-repeat center/cover;
  position: relative;
  text-align: center;
  color: $gray-600;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  user-select: none;

  i {
    font-size: 125%;
    vertical-align: sub;
  }
}

.avatar-status {
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #fff;
  background: $gray-600;
  border-radius: 50%;
}

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
}

.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
}

.avatar-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.25rem;
}

.avatar-xl {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.75rem;
}

.avatar-xxl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 2rem;
}

.avatar-placeholder {
  background: $gray-400
    url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="' + $gray-600 + '" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>')
    no-repeat center/80%;
}

.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0;
  font-size: 0;

  .avatar {
    margin-bottom: 0.5rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.avatar-list-stacked {
  .avatar {
    margin-right: -0.8em !important;
  }

  .avatar {
    box-shadow: 0 0 0 2px #fff;
  }
}

@each $vendor, $color in $colors {
  .avatar-#{$vendor} {
    background-color: mix($color, #fff, 30%);
    color: $color;
  }
}
