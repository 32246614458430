.card {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;

  .card {
    box-shadow: none;
  }

  @media print {
    box-shadow: none;
    border: none;
  }
}

.card-body {
  flex: 1 1 auto;
  margin: 0;
  padding: $card-spacer-y $card-spacer-x;
  position: relative;

  & + & {
    border-top: 1px solid $border-color;
  }

  > :last-child {
    margin-bottom: 0;
  }

  @media print {
    padding: 0;
  }
}

.card-body-scrollable {
  overflow: auto;
}

.card-footer,
.card-bottom {
  padding: 1rem $card-spacer-x;
  background: none;
}

.card-footer {
  border-top: 1px solid $border-color;
  color: $text-muted-dark;
}

.card-header {
  background: none;
  padding: 0.5rem $card-spacer-x;
  display: flex;
  min-height: 3.5rem;
  align-items: center;

  .card-title {
    margin-bottom: 0;
  }

  &.border-0 + .card-body {
    padding-top: 0;
  }

  @media print {
    display: none;
  }
}

.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: $h4-font-size;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.5rem;
  //flex: 1;

  a {
    color: inherit;
  }

  &:only-child {
    margin-bottom: 0;
  }
}

.card-title small,
.card-subtitle {
  color: $text-muted;
  font-size: px2rem(14px);
  display: block;
  margin: -0.75rem 0 1rem;
  line-height: 1.1;
  font-weight: 400;
}

.card-table {
  margin-bottom: 0;

  tr {
    &:first-child {
      td,
      th {
        border-top: 0;
      }
    }

    td,
    th {
      &:first-child {
        padding-left: $card-spacer-x;
      }

      &:last-child {
        padding-right: $card-spacer-x;
      }
    }
  }

  .card-body + & {
    border-top: 1px solid $border-color;
  }
}

.card-profile {
  .card-header {
    height: 9rem;
    background-size: cover;
  }
}

.card-profile-img {
  max-width: 6rem;
  margin-top: -5rem;
  margin-bottom: 1rem;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.card-link {
  + .card-link {
    margin-left: 1rem;
  }
}

.card-list-group {
  .card-body + & {
    border-top: 1px solid $border-color;
  }

  .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      border-top: 0;
    }
  }
}

.card-header-tabs {
  margin: -1.25rem 0;
  border-bottom: 0;
  line-height: 2rem;

  .nav-item {
    margin-bottom: 1px;
  }
}

.card-header-pills {
  margin: -0.75rem 0;
}

.card-aside {
  flex-direction: row;
}

.card-aside-column {
  min-width: 5rem;
  width: 30%;
  flex: 0 0 30%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: no-repeat center/cover;
}

.card-value {
  font-size: 2.5rem;
  line-height: 3.4rem;
  height: 3.4rem;
  display: flex;
  align-items: center;
  font-weight: 400;

  i {
    vertical-align: middle;
  }
}

.card-text {
}

.card-chart-bg {
  height: 4rem;
  margin-top: -1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.card-options {
  margin-left: auto;
  display: flex;
  order: 100;
  margin-right: -0.5rem;
  color: $text-muted;
  align-self: center;

  a:not(.btn) {
    margin-left: 0.5rem;
    color: $text-muted;
    display: inline-block;
    min-width: 1rem;

    &:hover {
      text-decoration: none;
      color: $text-muted-dark;
    }

    i {
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

/*
Card options
 */
.card-collapsed {
  > :not(.card-header):not(.card-status) {
    display: none;
  }
}

.card-options-collapse {
  i:before {
    .card-collapsed & {
      content: "\e92d";
    }
  }
}

.card-options-fullscreen {
  i:before {
    .card-fullscreen & {
      content: "\e992";
    }
  }
}

.card-options-remove {
  .card-fullscreen & {
    display: none;
  }
}

/*
Card maps
 */
.card-map {
  height: 15rem;
  background: $gray-200;
}

.card-map-placeholder {
  background: no-repeat center;
}

/**
Card tabs
 */
.card-tabs {
  display: flex;
}

.card-tabs-bottom {
  .card-tabs-item {
    border: 0;
    border-top: 1px solid $border-color;

    &.active {
      border-top-color: #fff;
    }
  }
}

.card-tabs-item {
  flex: 1 1 auto;
  display: block;
  padding: 1rem $card-spacer-x;
  border-bottom: 1px solid $border-color;
  color: inherit;
  overflow: hidden;

  @at-root a#{&} {
    background: #fafbfc;

    &:hover {
      text-decoration: none;
      color: inherit;
    }

    &:focus {
      z-index: 1;
    }

    &.active {
      background: #fff;
      border-bottom-color: #fff;
    }
  }

  & + & {
    border-left: 1px solid $border-color;
  }
}

/**
Card status
 */
.card-status {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 3px;
  border-radius: $card-border-radius $card-border-radius 0 0;
  background: $border-color;
}

.card-status-left {
  right: auto;
  bottom: 0;
  height: auto;
  width: 3px;
  border-radius: $card-border-radius 0 0 $card-border-radius;
}

/**
Card icon
 */
.card-icon {
  width: 3rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
}

/**
Card fullscreen
 */
.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}

/**
Card alert
 */
.card-alert {
  border-radius: 0;
  margin: -1px -1px 0;
}

.card-category {
  font-size: px2rem(14px);
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 0 0 0.5rem;
}
