.link-overlay {
  position: relative;

  &:hover {
    .link-overlay-bg {
      opacity: 1;
    }
  }
}

.link-overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($blue, 0.8);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: px2rem(20px);
  opacity: 0;
  transition: 0.3s opacity;
}
