.nav-link,
.nav-item {
  padding: 0 0.75rem;
  min-width: 2rem;
  transition: 0.3s color;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.2rem 0.25rem;
    min-width: 1rem;
  }
}

.nav-tabs {
  user-select: none;
  color: $text-muted;
  margin: 0 -0.75rem;

  .nav-link {
    border: 0;
    color: inherit;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    transition: 0.3s border-color;
    font-weight: 400;
    padding: 1rem 0;

    &:hover:not(.disabled) {
      border-color: $text-muted-dark;
      color: $text-muted-dark;
    }

    &.active {
      border-color: $blue;
      color: $blue;
      background: transparent;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
    }
  }

  .nav-item {
    margin-bottom: 0;
    position: relative;

    i {
      margin-right: 0.25rem;
      line-height: 1;
      font-size: px2rem(14px);
      width: px2rem(14px);
      vertical-align: baseline;
      display: inline-block;
    }

    &:hover {
      .nav-submenu {
        display: block;
      }
    }
  }

  .nav-submenu {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid $border-color;
    border-top: none;
    z-index: 10;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    min-width: 10rem;
    border-radius: 0 0 3px 3px;

    .nav-item {
      display: block;
      padding: 0.5rem 1rem;
      color: $text-muted;
      margin: 0 !important;
      cursor: pointer;
      transition: 0.3s background;

      &.active {
        color: $link-color;
      }

      &:hover {
        color: $text-muted-dark;
        text-decoration: none;
        background: rgba(0, 0, 0, 0.024);
      }
    }
  }
}

.nav-link {
  //display: block;
  //padding: 0.25rem 0.75rem;
}
