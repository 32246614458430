.container {
  @media print {
    max-width: none;
  }
}

.row-cards {
  > .col,
  > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }
}

.row-deck {
  > .col,
  > [class*="col-"] {
    display: flex;
    align-items: stretch;

    .card {
      flex: 1 1 auto;
    }
  }
}

.col-text {
  max-width: 48rem;
}

.col-login {
  max-width: 24rem;
}

@each $name, $value in (0: 0, xs: 0.25rem, sm: 0.5rem, lg: 1rem, xl: 1.5rem) {
  .gutters-#{$name} {
    margin-right: (-$value);
    margin-left: -($value);

    > .col,
    > [class*="col-"] {
      padding-right: $value;
      padding-left: $value;
    }

    .card {
      margin-bottom: 2 * $value;
    }
  }
}
