.aside {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: $aside-width;
  background: #ffffff;
  border-left: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  z-index: 100;
  visibility: hidden;
  box-shadow: 0 0 5px 2px rgba(#000, 0.05);

  body.aside-opened & {
    @media (min-width: 1600px) {
      visibility: visible;
    }
  }
}

.aside-body {
  padding: 1.5rem;
  flex: 1 1 auto;
  overflow: auto;
}

.aside-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid $border-color;
}

.aside-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $border-color;
}
