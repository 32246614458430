.page-breadcrumb {
  background: none;
  padding: 0;
  margin: 1rem 0 0;
  font-size: px2rem(14px);

  @include media-breakpoint-up(md) {
    margin: -0.5rem 0 0;
  }

  .breadcrumb-item {
    color: $text-muted;

    &.active {
      color: $text-muted-dark;
    }
  }
}
